import React from "react";
import ReactDOM from "react-dom/client";
import App from "../../../components/app";
import PreMatchTasks from "../../../components/sign_up/pre_match_tasks";

function init() {
  const componentElements = document.querySelectorAll(
    '[data-react-class="sign_up/pre_match_tasks"]',
  );
  componentElements.forEach((rootElement) => {
    const classString = rootElement.getAttribute("data-react-class");
    const propsJson = rootElement.getAttribute("data-react-props");
    const props = rootElement && JSON.parse(propsJson ?? "");
    if (classString) {
      const root = ReactDOM.createRoot(
        document.getElementById("pre-match-tasks-root-container") as HTMLElement,
      );

      root.render(
        <App initialUserId={props.userId}>
          <PreMatchTasks {...props} />
        </App>,
      );
    }
  });
}

if (document.readyState === "complete" || document.readyState === "interactive") {
  // document has at least been parsed
  init();
} else {
  document.addEventListener("DOMContentLoaded", () => {
    init();
  });
}
