import React, { useState, useEffect } from "react";
import {
  BulletedNotificationContainer,
  ParagraphContainer,
  StyledForm,
  FormControlOverrides,
} from "../shared/login_and_sign_up/pre_match_tasks_styles";
import { LabelTranslator } from "../../utils/label_utils";
import styled from "styled-components";
import {
  BulletedNotification,
  NotificationColorSchemeEnum,
  NotificationKindEnum,
} from "../components/base_ui/progress_and_validation/notification";
import { Block } from "baseui/block";
import { SectionBlock, ButtonsContainer } from "../components/base_ui/surfaces/section_block";
import { Button } from "../components/base_ui/inputs/button";
import FormControl from "../components/base_ui/inputs/form_control";
import Input from "../components/base_ui/inputs/input";
import { ParagraphMedium } from "baseui/typography";
import { PhoneInput, COUNTRIES } from "../components/base_ui/inputs/phone_input";

type Props = {
  participantId: string;
  role: string;
  programShortName: string;
  programTitle: string;
  existingFirstName: string;
  askForName: boolean;
  askForSchoolIdentifier: boolean;
  nameOfSchoolIdentifier: string;
  postToPath: string;
  csrfToken: string;
  onComplete: () => void;
  translator: Pick<LabelTranslator, "transformText">;
};

export const StyledOptionalText = styled(ParagraphMedium)`
  color: ${({ theme }) => theme.colors.contentTertiary};
  display: inline;
`;

export default function BasicInfoForm({
  participantId,
  role,
  programShortName,
  programTitle,
  existingFirstName,
  askForName,
  askForSchoolIdentifier,
  nameOfSchoolIdentifier,
  postToPath,
  csrfToken,
  onComplete,
  translator,
}: Props) {
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneCountry, setPhoneCountry] = useState(COUNTRIES.US);
  const [phoneError, setPhoneError] = useState("");
  const [schoolIdentifier, setSchoolIdentifier] = useState("");
  const [schoolIdentifierError, setSchoolIdentifierError] = useState("");
  const [errors, setErrors] = useState<string[]>([]);
  const [processingSubmission, setProcessingSubmission] = useState(false);

  const isValidForm = () => {
    let errorMessage = "";

    if (askForName) {
      if (firstName.trim() === "") {
        errorMessage = "First name is required.";
        setFirstNameError(errorMessage);
      }

      if (lastName.trim() === "") {
        errorMessage = "Last name is required.";
        setLastNameError(errorMessage);
      }
    }

    return errorMessage === "" ? true : false;
  };

  const handleSubmit = async () => {
    setProcessingSubmission(true);
    const isValid = isValidForm();

    if (isValid) {
      try {
        const response = await fetch(postToPath, {
          method: "POST",
          headers: {
            "X-CSRF-Param": "authenticity_token",
            "X-CSRF-Token": csrfToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          credentials: "same-origin",
          body: JSON.stringify({
            participant_id: participantId,
            first_name: firstName,
            last_name: lastName,
            phone: phone ? phoneCountry.dialCode + phone : "",
            phone_country_code: phoneCountry.id,
            school_identifier: schoolIdentifier,
          }),
        });

        const parsedResponse = await response.json();

        // Display any errors coming from the backend
        if (parsedResponse.errors) {
          if (parsedResponse.errors[0].includes("The given phone number is invalid")) {
            setPhoneError(parsedResponse.errors[0]);
          } else {
            setErrors(parsedResponse.errors);
          }
        } else {
          onComplete();
        }
      } catch (r) {
        setErrors([
          "Uh oh - an unexpected error occurred. Please try again or contact help@mentorcollective.org for assistance.",
        ]);
      }
    }

    setProcessingSubmission(false);
  };

  // handle updating the list of errors
  useEffect(() => {
    const newErrors: string[] = [];

    firstNameError && newErrors.push(firstNameError);
    lastNameError && newErrors.push(lastNameError);
    phoneError && newErrors.push(phoneError);
    schoolIdentifierError && newErrors.push(schoolIdentifierError);

    setErrors(newErrors);
  }, [firstNameError, lastNameError, phoneError, schoolIdentifierError]);

  const withOptionalTag = (text: string) => {
    return (
      <>
        {text} <StyledOptionalText>(Optional)</StyledOptionalText>
      </>
    );
  };

  const schoolIdLabel = nameOfSchoolIdentifier || "School ID";

  return (
    <>
      <Block maxWidth="900px" margin="auto" padding="16px" data-test="basic-info-form-container">
        {errors.length > 0 && (
          <BulletedNotificationContainer>
            <BulletedNotification
              bulletedMessages={errors}
              notificationHeader={
                <>
                  <b>Errors found in the following fields.</b> Please review them before
                  resubmitting:
                </>
              }
              colorScheme={NotificationColorSchemeEnum.LIGHT}
              kind={NotificationKindEnum.NEGATIVE}
              showIcon={true}
              isFullWidth={true}
              dataTest={"registration-basic-info-form-errors"}
            />
          </BulletedNotificationContainer>
        )}

        <SectionBlock
          title="Complete Profile"
          button={
            <ButtonsContainer>
              <Button
                disabled={processingSubmission}
                onClick={handleSubmit}
                dataAnalyticsId={`sign-up-flow-done-${
                  role === "Mentor" ? "mentor" : "mentee"
                }-button`}
                dataTest={"registration-save-complete-profile"}
              >
                Submit and Continue
              </Button>
            </ButtonsContainer>
          }
          showFooterHorizontalRule
          data-test={"registration-basic-info-form"}
        >
          <ParagraphContainer>
            <ParagraphMedium>
              {existingFirstName ? existingFirstName + ", welcome" : "Welcome"} to the{" "}
              {programShortName} {programTitle} community! The next few steps will help us
              personalize your {translator.transformText("mentorship")}.
            </ParagraphMedium>
            <ParagraphMedium>
              Your phone number will be shared with your{" "}
              {translator.transformText(role === "Student" ? "mentor" : "mentee")} when you are
              matched (and in exchange, you will receive theirs) if your{" "}
              {translator.transformText("school")} allows. We will also use it to check in with you
              about your experience in the program. You can unsubscribe at any time.
            </ParagraphMedium>
          </ParagraphContainer>
          <StyledForm>
            {askForName && (
              <>
                <FormControl
                  label="First Name"
                  error={firstNameError}
                  overrides={FormControlOverrides}
                >
                  <Input
                    aria-label="First Name"
                    placeholder="Enter your first name"
                    required
                    value={firstName}
                    onChange={(e) => {
                      setFirstNameError("");
                      setFirstName(e.currentTarget.value);
                    }}
                    dataTest={"registration-first-name-input"}
                  />
                </FormControl>
                <FormControl
                  label="Last Name"
                  error={lastNameError}
                  overrides={FormControlOverrides}
                >
                  <Input
                    aria-label="Last Name"
                    placeholder="Enter your last name"
                    required
                    value={lastName}
                    onChange={(e) => {
                      setLastNameError("");
                      setLastName(e.currentTarget.value);
                    }}
                    dataTest={"registration-last-name-input"}
                  />
                </FormControl>
              </>
            )}
            <FormControl
              label={withOptionalTag("Phone Number")}
              error={phoneError}
              overrides={FormControlOverrides}
            >
              <PhoneInput
                aria-label="Phone Number"
                name="registration-phone-input"
                placeholder="(000) 000-0000"
                country={phoneCountry}
                onCountryChange={({ option }) => {
                  setPhoneError("");
                  // eslint-disable-next-line
                  // @ts-ignore
                  setPhoneCountry(option);
                }}
                text={phone}
                onTextChange={(e) => {
                  setPhoneError("");
                  setPhone(e.currentTarget.value);
                }}
                data-test={"registration-phone-input"}
              />
            </FormControl>
            {askForSchoolIdentifier && (
              <FormControl
                label={withOptionalTag(schoolIdLabel)}
                error={schoolIdentifierError}
                overrides={FormControlOverrides}
              >
                <Input
                  aria-label={schoolIdLabel}
                  placeholder={`Enter your ${schoolIdLabel}`}
                  value={schoolIdentifier}
                  onChange={(e) => {
                    setSchoolIdentifierError("");
                    setSchoolIdentifier(e.currentTarget.value);
                  }}
                  dataTest={"registration-school-id-input"}
                />
              </FormControl>
            )}
          </StyledForm>
        </SectionBlock>
      </Block>
    </>
  );
}
