import styled from "styled-components";
import { Theme } from "baseui/theme";

export const BulletedNotificationContainer = styled.div`
  margin-top: -${({ theme }) => theme.sizing.scale800};
  margin-bottom: ${({ theme }) => theme.sizing.scale900};
  margin-left: -${({ theme }) => theme.sizing.scale600};
  margin-right: -${({ theme }) => theme.sizing.scale600};

  ul {
    padding-left: ${({ theme }) => theme.sizing.scale1000};
  }

  @media ${({ theme }) => theme.deprecatedBreakpoints.small} {
    margin-top: ${({ theme }) => theme.sizing.scale800};
  }
`;

export const ParagraphContainer = styled.div`
  max-width: 700px;
  margin-bottom: ${({ theme }) => theme.sizing.scale800};
`;

export const StyledForm = styled.form`
  max-width: 400px;
`;

export const FormControlOverrides = {
  Label: {
    style: () => ({
      marginTop: "0",
      marginBottom: "0",
      textTransform: "none",
    }),
  },
  ControlContainer: {
    style: ({ $theme }: { $theme: Theme }) => ({
      marginBottom: $theme.sizing.scale800,
    }),
  },
};
