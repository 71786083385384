// Third-party imports
import React, { useState } from "react";

// Our imports
import deprecatedTheme from "../styling/deprecated_theme";
import { Grid, Cell } from "baseui/layout-grid";
import ProgramSchoolLogoBlock from "../shared/login_and_sign_up/program_school_logo_block";
import { LabelTranslator } from "../../utils/label_utils";
import {
  ProgressStep,
  ProgressSteps,
} from "../components/base_ui/progress_and_validation/progress_steps";
import { ProgressBar } from "../components/base_ui/progress_and_validation/progress_bar";
import { SIZE } from "baseui/progress-bar";
import BasicInfoForm from "./basic_info_form";
import MatchingSurvey from "./matching_survey";
import OnDemandCourse from "./on_demand_course";
import ResetPasswordTask from "./reset_password_task";
import styled from "styled-components";
import { LabelMedium, ParagraphMedium } from "baseui/typography";
import { Theme } from "../styling/baseui_theme";

const ProgramSchoolLogoBlockContainer = styled.div`
  max-width: 400px;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;

  @media ${deprecatedTheme.deprecatedBreakpoints.small} {
    max-width: 90%;
  }
`;

const ProgressStepsContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.sizing.scale900};
`;

const ProgressBarContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizing.scale800};
  margin-bottom: ${({ theme }) => theme.sizing.scale900};
`;

// Ensure a minimum margin for small screens
const RegistrationMatchingSurveyContainer = styled.div`
  margin-left: ${({ theme }) => theme.sizing.scale600};
  margin-right: ${({ theme }) => theme.sizing.scale600};
`;

const progressBarOverrides = {
  Root: {
    style: ({ $theme }: { $theme: Theme }) => ({
      backgroundColor: $theme.colors.white,
      paddingBottom: $theme.sizing.scale500,
      marginBottom: $theme.sizing.scale900,
    }),
  },
  BarContainer: {
    style: ({ $theme }: { $theme: Theme }) => ({
      paddingTop: $theme.sizing.scale500,
    }),
  },
  BarProgress: {
    style: () => ({
      animationDuration: "5s",
    }),
  },
};

const StyledParagraphMedium = styled(ParagraphMedium)`
  margin-top: 0;
  margin-bottom: 0;
`;

export interface Task {
  name: string;
  completed: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

const tasksToDisplay = [
  "reset-password-task",
  "basic-info-form",
  "matching-survey-task",
  "on-demand-course",
];

// This component is responsible for rendering the various tasks that the participant completes as
// part of the sign-up flow.
function PreMatchTasks({
  role,
  programData,
  userId,
  firstName,
  lastName,
  email,
  participantId,
  seedTasks,
  goToOnAllTasksCompletedPath,
  csrfToken,
}: {
  role: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  programData: any;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  participantId: string;
  seedTasks: Task[];
  goToOnAllTasksCompletedPath: string;
  csrfToken: string;
}) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (typeof pendo !== "undefined" && userId) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    pendo.initialize({
      visitor: {
        id: userId,
        firstName: firstName,
        lastName: lastName,
        email: email,
      },
    });
  }

  // check size of window to determine if we should show mobile view - the mobile view
  // layout uses the ProgressBar instead of the ProgressSteps component, which is quite
  // different so assigning this boolean upfront to determine which view to show
  const mobileView = window.matchMedia(deprecatedTheme.deprecatedBreakpoints.small).matches;

  // The tasks coming from the BE don't always match what we need for the FE so we are
  // stripping out some tasks that we don't want to display as steps.
  const [tasks, setTasks] = useState(
    seedTasks.filter((task) => tasksToDisplay.includes(task.name)),
  );
  const taskInProgress = tasks.findIndex((task) => !task.completed);

  // If there are no tasks to display, redirect (this happens when user
  // has finished registering and opens the registration link again)
  if (taskInProgress === -1) {
    window.location.replace(goToOnAllTasksCompletedPath);
  }

  const [currentStep, setCurrentStep] = useState(taskInProgress);

  const program = {
    id: programData.id,
    shortName: programData.shortName,
    programSequence: {
      id: programData.programSequenceId,
      higherEdSegment: programData.higherEdSegment.toUpperCase(),
    },
    programCustomLabel: programData.programCustomLabel,
  };
  const translator = new LabelTranslator(program);

  const stepFor = (task: Task) => {
    const onComplete = () => {
      // Replace tasks with a copy of tasks, except this latest task is marked as complete.
      tasks[currentStep] = Object.assign({}, tasks[currentStep], { completed: true });
      setTasks(tasks);

      // Some tasks might have already been completed previously, so we need to make sure
      // we are counting the current step correctly (e.g. if the user is resetting
      // password after they've already completed some other tasks).
      const currentStepInProgress = tasks.findIndex((task) => !task.completed);

      // If all the tasks are now complete, move the user forward.
      if (currentStepInProgress === -1) {
        window.location.replace(goToOnAllTasksCompletedPath);
      } else {
        setCurrentStep(currentStepInProgress);
      }
    };

    switch (task.name) {
      case "basic-info-form":
        return {
          title: "Complete Profile",
          content: (
            <BasicInfoForm
              participantId={participantId}
              role={role}
              programShortName={programData.shortName}
              programTitle={programData.title}
              existingFirstName={firstName}
              askForName={task.data.askForName}
              askForSchoolIdentifier={task.data.askForSchoolIdentifier}
              nameOfSchoolIdentifier={task.data.nameOfSchoolIdentifier}
              postToPath={task.data.postToPath}
              csrfToken={csrfToken}
              onComplete={onComplete}
              translator={translator}
            />
          ),
        };
      case "matching-survey-task":
        return {
          title: "Matching Survey",
          content: (
            <RegistrationMatchingSurveyContainer>
              <MatchingSurvey
                role={role}
                schoolName={programData.schoolName}
                surveyId={task.data.surveyId}
                surveyData={task.data.surveyData}
                onComplete={onComplete}
                translator={translator}
              />
            </RegistrationMatchingSurveyContainer>
          ),
        };
      case "on-demand-course":
        return {
          title: "On-Demand Training",
          content: (
            <OnDemandCourse
              takeOnDemandCoursePath={task.data.takeOnDemandCoursePath}
              onComplete={onComplete}
              translator={translator}
            />
          ),
        };
      case "reset-password-task":
        return {
          title: "Reset Your Password",
          content: (
            <ResetPasswordTask
              userId={userId}
              translator={translator}
              programTitle={programData.title}
              programShortName={programData.shortName}
              postToPath={task.data.updatePasswordPath}
              onComplete={onComplete}
              csrfToken={csrfToken}
            />
          ),
        };
    }
  };

  const steps = tasks.map((task) => {
    return stepFor(task);
  }) as ProgressStep[];

  // Add an empty step for the end (more for expectations than anything else)
  steps.push({
    title: "Explore Dashboard",
    content: <></>,
  });

  // Set up for progress bar view when on mobile
  const barCurrentStepComponent = taskInProgress > -1 && steps[currentStep].content;
  const barCurrentStepVisual = currentStep + 1;
  // A couple of key points to note here:
  //  1. currentStep is 0-indexed, so we can use it as the number of steps
  //     already completed for the value calculation
  //  2. The progress bar doesn't handle steps very well when the value has
  //     decimals (for example when there are 3 steps and 1 step is completed,
  //     the value might be 33.333333 but that causes the ProgressBar to reflect
  //     step 1 as the current step instead of step 2). So we round the value
  //     up to the nearest integer so that the progress bar reflects the right
  //     step.
  const barCurrentValue = Math.ceil((currentStep / steps.length) * 100);

  return (
    <Grid gridGutters={0}>
      <Cell span={[0, 1, 2]}></Cell>
      <Cell span={[4, 6, 8]}>
        <ProgramSchoolLogoBlockContainer>
          <ProgramSchoolLogoBlock
            programLogo={programData.logo}
            programName={programData.title}
            schoolLogo={programData.schoolLogo}
            schoolName={programData.schoolName}
          />
        </ProgramSchoolLogoBlockContainer>
        {barCurrentStepComponent && (
          <>
            {!mobileView ? (
              <ProgressStepsContainer>
                <ProgressSteps
                  steps={steps}
                  current={currentStep}
                  setCurrent={setCurrentStep}
                  dataTest="registration-pre-match-task-progress-steps"
                />
              </ProgressStepsContainer>
            ) : (
              <ProgressBarContainer>
                <ProgressBar
                  value={barCurrentValue}
                  steps={steps.length}
                  showLabel={true}
                  getProgressLabel={() => {
                    return (
                      <>
                        <LabelMedium>
                          {barCurrentStepVisual} of {steps.length} - {steps[currentStep].title}
                        </LabelMedium>
                        <StyledParagraphMedium>
                          Next: {steps[currentStep + 1].title}
                        </StyledParagraphMedium>
                      </>
                    );
                  }}
                  ariaLabel={`${barCurrentStepVisual} of ${steps.length} steps. Current step ${
                    steps[currentStep].title
                  }. Next step: ${steps[currentStep + 1].title}`}
                  size={SIZE.medium}
                  styletronOverrides={progressBarOverrides}
                  dataTest="registration-pre-match-task-progress-bar"
                />
                {barCurrentStepComponent}
              </ProgressBarContainer>
            )}
          </>
        )}
      </Cell>
      <Cell span={[0, 1, 2]}></Cell>
    </Grid>
  );
}

export default PreMatchTasks;
