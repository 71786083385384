import React, { useState, FormEvent } from "react";
import {
  BulletedNotificationContainer,
  ParagraphContainer,
  StyledForm,
  FormControlOverrides,
} from "../shared/login_and_sign_up/pre_match_tasks_styles";
import { SectionBlock, ButtonsContainer } from "../components/base_ui/surfaces/section_block";
import { ParagraphMedium } from "baseui/typography";
import { LabelTranslator } from "../../utils/label_utils";
import { passwordRegex, passwordRegexMismatchMessage } from "../../utils/string_utils";
import useForm from "../shared/use_form";
import FormControl from "../components/base_ui/inputs/form_control";
import Input from "../components/base_ui/inputs/input";
import * as yup from "yup";
import { Button } from "../components/base_ui/inputs/button";
import {
  BulletedNotification,
  NotificationColorSchemeEnum,
  NotificationKindEnum,
} from "../components/base_ui/progress_and_validation/notification";
import { Block } from "baseui/block";

type Props = {
  userId: string;
  translator: Pick<LabelTranslator, "transformText">;
  postToPath: string;
  programShortName: string;
  programTitle: string;
  onComplete: () => void;
  csrfToken: string;
};

export default function ResetPasswordTask({
  programShortName,
  programTitle,
  translator,
  onComplete,
  csrfToken,
  postToPath,
  userId,
}: Props) {
  const [updating, setUpdating] = useState(false);
  const [requestErrors, setRequestErrors] = useState<string[]>([]);

  const initialFormData = {
    password: "",
    confirmPassword: "",
  };

  const schema = yup.object().shape({
    password: yup
      .string()
      .matches(passwordRegex, passwordRegexMismatchMessage)
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords do not match")
      .required("Confirm password is required"),
  });

  const { form, validateForm, hasErrors } = useForm(initialFormData, schema, true);

  const save = async (event: FormEvent) => {
    event.preventDefault();

    try {
      await validateForm();
    } catch (error) {
      return;
    }

    try {
      setUpdating(true);

      const response = await fetch(postToPath, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({
          user_id: userId,
          new_password: form.password.value,
        }),
      });

      const parsedResponse = await response.json();

      if (parsedResponse.errors) {
        setRequestErrors(parsedResponse.errors);
      } else {
        onComplete();
      }
    } catch (error) {
      setRequestErrors([
        "Uh oh - an unexpected error occurred. Please try again or contact help@mentorcollective.org for assistance.",
      ]);
    } finally {
      setUpdating(false);
    }
  };

  const notificationHeader =
    requestErrors.length > 0 ? (
      <> There was an error submitting your new password:</>
    ) : (
      <>
        <b>Error(s) found in the following form fields.</b> Please review them before resubmitting:
      </>
    );

  const fieldsWithErrors = Object.keys(form)
    .filter((key) => form[key as keyof typeof form].errors.length > 0)
    .map((key) => {
      return key === "password" ? "Password" : "Confirm Password";
    });

  const displayErrors = (requestErrors.length > 0 || hasErrors) && !updating;
  const errorMessages = requestErrors.length > 0 ? requestErrors : fieldsWithErrors;

  return (
    <Block maxWidth="900px" margin="auto" padding="16px" data-test="password-reset-form-container">
      {displayErrors && (
        <BulletedNotificationContainer>
          <BulletedNotification
            bulletedMessages={errorMessages}
            retainListFormatForOneBullet
            notificationHeader={notificationHeader}
            colorScheme={NotificationColorSchemeEnum.LIGHT}
            kind={NotificationKindEnum.NEGATIVE}
            showIcon={true}
            isFullWidth={true}
            dataTest={"registration-basic-info-form-errors"}
          />
        </BulletedNotificationContainer>
      )}
      <SectionBlock
        title="Reset Password"
        button={
          <ButtonsContainer>
            <Button
              disabled={updating}
              onClick={save}
              dataTest="registration-save-reset-password-task"
              dataAnalyticsId="registration-save-reset-password-task"
            >
              Submit and Continue
            </Button>
          </ButtonsContainer>
        }
        showFooterHorizontalRule
        data-test="registration-reset-password-form"
      >
        <ParagraphContainer>
          <ParagraphMedium>
            Please reset your Mentor Collective account password to continue registering for{" "}
            {programShortName} {programTitle}. Keep this in a safe place - you'll need it to log
            into your Dashboard and take part in your {translator.transformText("mentorship")}.
          </ParagraphMedium>
        </ParagraphContainer>
        <StyledForm>
          {/* Satisfy the browser's password requirements by including a hidden input field */}
          <input
            hidden
            type="text"
            name="username"
            autoComplete="username"
            aria-label="hidden username input"
          />
          <FormControl
            label={"Password"}
            caption={
              "Must be 10 or more characters and contain at least 1 uppercase character, 1 number, and 1 special character."
            }
            dataTest="new-password-form-control"
            error={form.password.errors.join(" ")}
            overrides={FormControlOverrides}
          >
            <Input
              required
              type="password"
              aria-label="password input"
              dataTest={"password-input"}
              value={form.password.value}
              onChange={({ currentTarget: { value } }) => {
                form.password.setTouched(false);
                form.password.set(value);
              }}
              autoComplete="new-password"
              placeholder="Enter your password"
            />
          </FormControl>
          <FormControl
            label={"Confirm Password"}
            dataTest="confirm-password-form-control"
            error={form.confirmPassword.errors.join(" ")}
            overrides={FormControlOverrides}
          >
            <Input
              required
              type="password"
              aria-label="confirm password input"
              dataTest={"confirm-password-input"}
              value={form.confirmPassword.value || ""}
              onChange={({ currentTarget: { value } }) => {
                form.confirmPassword.setTouched(false);
                form.confirmPassword.set(value);
              }}
              autoComplete="new-password"
              placeholder="Confirm your password"
            />
          </FormControl>
        </StyledForm>
      </SectionBlock>
    </Block>
  );
}
