import React from "react";
import { LabelTranslator } from "../../utils/label_utils";
import { SectionBlock, ButtonsContainer } from "../components/base_ui/surfaces/section_block";
import { Button } from "../components/base_ui/inputs/button";
import { KIND } from "baseui/button";
import { ParagraphMedium } from "baseui/typography";
import { Block } from "baseui/block";

type Props = {
  translator: Pick<LabelTranslator, "transformText">;
  takeOnDemandCoursePath: string;
  onComplete: () => void;
};

export default function OnDemandCourse({ takeOnDemandCoursePath, translator, onComplete }: Props) {
  const handleCompleteTrainingClicked = () => {
    window.open(takeOnDemandCoursePath, "_blank");
    onComplete();
  };

  const handleCompleteTrainingLaterClicked = () => {
    window.open("/participant_dashboard", "_self");
  };

  return (
    <Block maxWidth="900px" margin="auto" padding="16px" data-test="on-demand-course-container">
      <SectionBlock
        title="On-Demand Training"
        showFooterHorizontalRule
        footer={
          <ButtonsContainer>
            <Button
              onClick={handleCompleteTrainingClicked}
              dataTest="complete-training-now-button"
              dataAnalyticsId="sign-up-flow-on-demand-complete-mentor-training-button"
            >
              Complete Training Now
            </Button>

            <Button
              onClick={handleCompleteTrainingLaterClicked}
              dataTest="complete-training-later-button"
              dataAnalyticsId="sign-up-flow-on-demand-mentor-training-later-button"
              kind={KIND.tertiary}
            >
              Complete Training Later
            </Button>
          </ButtonsContainer>
        }
      >
        <ParagraphMedium>
          Before we can find you a {translator.transformText("mentee")}, you'll need to complete a
          required training. This self-paced course should take you 30 to 45 minutes and features an
          overview of the program, its expectations, and best practices for being an effective{" "}
          {translator.transformText("mentor")}.
        </ParagraphMedium>
        <ParagraphMedium>
          We recommend you complete this training in the next three days — the sooner you're done,
          the sooner we can match you! Once you're done with this step, we'll take you to your
          Dashboard so that you can start exploring it!
        </ParagraphMedium>
      </SectionBlock>
    </Block>
  );
}
