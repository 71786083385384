import React from "react";
import { LabelTranslator } from "../../utils/label_utils";
import { ParagraphMedium } from "baseui/typography";
import styled from "styled-components";
import SurveyPage from "../surveys/survey_page";
import { Block } from "baseui/block";

const SurveyContainer = styled(Block)`
  // adding some extra styling for the survey error notification
  #survey-validation-message {
    margin-top: -${({ theme }) => theme.sizing.scale300};
    margin-bottom: ${({ theme }) => theme.sizing.scale300};

    ul {
      padding-left: ${({ theme }) => theme.sizing.scale1000};
    }
  }
`;

const StyledSurvey = styled(SurveyPage)`
  max-width: 700px;
  text-align: left;
  margin: auto;

  // Hacking styles because page CSS is overwriting our survey styles
  .text-input {
    width: 100%;
    text-align: left;
  }

  label {
    letter-spacing: unset;
    text-transform: none;
  }
  // End of hacks

  @media ${({ theme }) => theme.deprecatedBreakpoints.small};
    max-width: 90vw;
  }
`;

const IntroTextContainer = styled.div`
  padding-left: ${({ theme }) => theme.sizing.scale300};
  max-width: 900px;
  p {
    margin-top: 0;
  }
`;

type Props = {
  role: string;
  schoolName: string;
  surveyId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  surveyData: any;
  onComplete: () => void;
  translator: Pick<LabelTranslator, "transformText">;
};

export default function MatchingSurvey({
  role,
  schoolName,
  surveyId,
  surveyData,
  onComplete,
  translator,
}: Props) {
  const introText = (
    <IntroTextContainer>
      {role === "Student" ? (
        <ParagraphMedium>
          Complete this survey so that we can find you {translator.transformText("a mentor")} who
          can help you meet your goals! Responses may be shared with your{" "}
          {translator.transformText("mentor")} so they can better support you.
        </ParagraphMedium>
      ) : (
        <ParagraphMedium>
          Complete this survey so we can connect you with {translator.transformText("mentees")} at{" "}
          {schoolName} who are waiting for your support! Responses may be shared with your{" "}
          {translator.transformText("mentees")} to jumpstart your{" "}
          {translator.transformText("mentorship")}.
        </ParagraphMedium>
      )}
    </IntroTextContainer>
  );

  return (
    <SurveyContainer data-test="matching-survey-form-container">
      <StyledSurvey
        id={surveyId}
        userId={surveyData.userId}
        context={{ participantId: surveyData.entityId }}
        introText={introText}
        inRegistrationFlow={true}
        onSubmit={onComplete}
      />
    </SurveyContainer>
  );
}
