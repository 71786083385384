import React from "react";
import {
  PhoneInput as BasePhoneInput,
  PhoneInputProps as BasePhoneInputProps,
  PhoneInputOverrides,
  SIZE,
} from "baseui/phone-input";
import { mergeOverrides } from "baseui";
import { Overrides } from "baseui/overrides";
import { Theme } from "baseui/theme";
import { InputProps, getOverrides as getInputOverrides } from "../input";

export * from "baseui/phone-input";

export type PhoneInputProps = Omit<
  BasePhoneInputProps,
  | "aria-label"
  | "aria-labelledby"
  | "aria-describedby"
  | "id"
  | "maxDropdownHeight"
  | "maxDropdownWidth"
  | "mapIsoToLabel"
  | "positive"
  | "disabled"
  | "error"
  | "required"
  | "size"
  | "overrides"
> & {
  disabled?: boolean;
  error?: boolean;
  required?: boolean;
  size?: (typeof SIZE)[keyof typeof SIZE];
  dataTest?: string;
  styletronOverrides?: PhoneInputOverrides;
};

const getOverrides = (
  props: PhoneInputProps,
): Overrides<
  BasePhoneInputProps & {
    $isFocused: boolean;
    $disabled: boolean;
    $error: boolean;
    $positive: boolean;
  }
> => {
  return {
    CountrySelect: {
      props: {
        overrides: {
          Popover: {
            props: {
              overrides: {
                Body: {
                  style: ({ $theme }: { $theme: Theme }) => ({
                    zIndex: $theme.zIndex.modal - 1,
                  }),
                },
              },
            },
          },
          ControlContainer: {
            style: ({
              $theme,
              $disabled,
              $isFocused,
              $positive,
              $error,
            }: {
              $theme: Theme;
              $disabled: boolean;
              $isFocused: boolean;
              $positive: boolean;
              $error: boolean;
            }) => {
              if (!$error && !$positive) {
                return {
                  backgroundColor: $isFocused
                    ? $theme.colors.mono100
                    : $theme.colors.backgroundAlwaysLight,
                  ...(!$isFocused && {
                    borderTopColor: $theme.colors.mono600,
                    borderBottomColor: $theme.colors.mono600,
                    borderLeftColor: $theme.colors.mono600,
                    borderRightColor: $theme.colors.mono600,
                  }),
                  ...($disabled && {
                    borderTopColor: $theme.colors.mono400,
                    borderBottomColor: $theme.colors.mono400,
                    borderLeftColor: $theme.colors.mono400,
                    borderRightColor: $theme.colors.mono400,
                  }),
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                };
              }
              if ($error) {
                return {
                  color: $theme.colors.negative500,
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                };
              }
              return {
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
              };
            },
          },
        },
      },
    },
    CountrySelectDropdown: {
      style: () => ({
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
      }),
    },
    Input: {
      props: {
        overrides: getInputOverrides(props as InputProps),
      },
    },
  };
};

export const PhoneInput = ({
  disabled = false,
  error = false,
  required = false,
  size = SIZE.default,
  styletronOverrides,
  dataTest = "phone-input",
  ...props
}: PhoneInputProps) => {
  return (
    <BasePhoneInput
      {...props}
      disabled={disabled}
      error={error}
      required={required}
      size={size}
      data-test={dataTest}
      overrides={mergeOverrides(
        getOverrides({ disabled, error, required, size, dataTest, ...props }),
        styletronOverrides as Overrides<
          BasePhoneInputProps & {
            $isFocused: boolean;
            $disabled: boolean;
            $error: boolean;
            $positive: boolean;
          }
        >,
      )}
    />
  );
};

export default PhoneInput;
